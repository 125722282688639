@tailwind base;
@tailwind components;
@tailwind utilities;

.page-content-footer {
  min-height: calc(100vh - 40px);
}

.page-content {
  min-height: calc(100vh - 87px);
}

.noWebkit {
  -webkit-appearance: none;
  -moz-appearance: none;
}

.h-inner-screen {
  height: 100vh;
  height: var(--inner-page-height);
}
.min-h-inner-screen {
  min-height: 100vh;
  min-height: var(--inner-page-height);
}

/*.btn-round{*/
/*  padding-left: 1.5rem;*/
/*  padding-right: 1.5rem;*/
/*  display: flex;*/
/*  color: white;*/
/*  border: 9999px;*/
/*  background: #FEE2E2;*/
/*  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);*/
/*  transition-duration: 500ms;*/
/*}*/

/*.btn-round:hover{*/
/*  opacity: 75%;*/

/*}*/ ;
